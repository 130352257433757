// Auth
export const ACCOUNT_ACTIVATION = 'account-activation/';
export const GOOGLE_LOGIN = 'google-login/';
export const GOOGLE_ONE_TAP_LOGIN = 'google-one-tap-login/';
export const FACEBOOK_LOGIN = 'facebook-login/';
export const SIGNIN = 'signin/';
export const SIGNUP = 'signup/';
export const FORGOT_PASSWORD = 'forgot-password/';
export const RESET_PASSWORD = 'reset-password/';
export const CREATE_NEW_MANAGER = 'create-new-manager/';
export const API_USER_DATA = 'user-data/';
export const GET_CETIFICATED = 'certificated/';

// Admin
export const ADMIN_ACCOUNT_UPDATE_DOMAIN = 'admin/account/update-domain/';
export const ADMIN_ACCOUNT_RESET_ACCOUNT = 'admin/account/reset-account/';
export const ADMIN_ACCOUNT_CRAWLER_RESET_V1 = 'admin/account/remove-forms-categories'; // V1
export const ADMIN_ACCOUNT_CRAWLER_RESET = 'admin/account/crawler-reset'; // V2
export const ADMIN_ACCOUNT_UPDATE_REVEALED_COMPANY = 'admin/account/update-revealed-company';
export const API_ADMIN_ACCOUNT_BEHAVIORAL_ANALYTICS = 'admin/account/behavioral-analytics';
export const API_ADMIN_ACCOUNT_TARGET_ACCOUNT = 'admin/account/target-account';
export const ADMIN_ACCOUNT_PROCESS_REQUEST_INTENT_CLASSIFICATION = 'admin/account/process-request-intent';
export const ADMIN_ACCOUNT = 'admin/account/';
export const ADMIN_ACCOUNTS = 'admin/accounts/';
export const ADMIN_ACTIVITY = 'admin/activity/';
export const ADMIN_NOTIFICATION = 'admin/notification/';
export const ADMIN_DASHBOARD = 'admin/dashboard/';
export const ADMIN_EVENT = 'admin/event/';
export const ADMIN_FEATURE = 'admin/feature/';
export const ADMIN_LISTENER = 'admin/listener/';
export const ADMIN_ORDER = 'admin/order/';
export const ADMIN_PACKAGE = 'admin/package/';
export const ADMIN_CONTRACT_PLAN = 'admin/contract-plan/';
export const ADMIN_PAUSE_CONTRACT_PLAN = 'admin/contract-plan/pause';
export const ADMIN_FILTER = 'admin/filter/';
export const ADMIN_USER = 'admin/user/';
export const USER = 'user/';
export const USER_UPDATE = 'user/update/';
export const ADMIN_PUBLISH_JSON = 'admin/publish-json';
export const ADMIN_UPDATE_RULE_CONDITIONS = 'admin/update-rule-conditions';
export const ADMIN_UPDATE_SYNC_TEST_ACCOUNT = 'admin/update-sync-test-account';
export const ADMIN_MEDIA = 'media';
export const ADMIN_TERMS = 'admin/terms/';
export const ADMIN_TERM = 'admin/term';
export const ADMIN_TERM_PUBLISH = 'admin/term-publish';
export const ADMIN_LISTENER_ACCOUNTS = '/admin/listener-accounts/';
export const ADMIN_USER_LIST_ACCOUNT = 'admin/user-accounts/';
export const ADMIN_USER_LIST_TERM = 'admin/user-terms/';
export const ADMIN_ACCOUNT_LIST_TERM = 'admin/account-terms/';
export const ADMIN_DESTINATIONS = 'admin/destination/';
export const ADMIN_ENABLE_PAGE_VIEW = 'admin/enable-page-view/';
export const ADMIN_ENABLE_ALL_FEATURES = 'admin/enable-all-feature/';
export const ADMIN_ADD_CONSENT_RULE_DEFAULT = 'admin/create-consent-rule-default/';
export const ADMIN_ADD_COMPLETE_SETUP_GUIDE = 'admin/create-complete-setup-guide/';
export const ADMIN_ADD_COMPLETE_CONVERSION_ROADMAP = 'admin/update-acount-conversion-roadmap/';
export const ADMIN_ADD_TRIGGERS_DEFAULT = 'admin/create-triggers-default/';
export const ADMIN_ENABLE_DESTINATION_GTM_DEFAULT = 'admin/enable-default-gtm/';
export const ADMIN_UPDATE_NUMEBR_LISTENER_PREMIUM = 'admin/update-number-premium/';
export const ADMIN_UPDATE_DOMAIN_IFRAME = 'admin/update-domain-iframe/';
export const ADMIN_INACTIVE_TEMPORARY_PERMANENT = 'admin/inactive-temporary-permanent';
export const ADMIN_ADD_ECOMMERCE_DEFAULT_TRIGGER = 'admin/add-ecommerce-default-trigger';
export const ADMIN_UPDATE_DESTINATIONS_JSON = 'admin/update-destinations-json';
export const ADMIN_UPDATE_ENGAGEMENT_RULE = 'admin/update-engagement-rule';
export const ADMIN_UPDATE_CLICK_ID_SETTINGS = 'admin/update-click-id-settings';
export const ADMIN_REMOVE_DEFAULT_SERVER_SIDE_DATA_ACTION = 'admin/remove-default-server-side-data-action';
export const API_ADMIN_CUSTOM_REPORT = 'admin/custom-reports';
export const API_ADMIN_DIMENSIONS = 'admin/dimensions';
export const API_ADMIN_DIMENSION_CREATE = 'admin/dimension/create';
export const API_ADMIN_DIMENSION_DELETE = 'admin/dimension/delete';
export const API_ADMIN_DIMENSION_ID = 'admin/dimension/:id';
export const API_ADMIN_DIMENSION_UPDATE = 'admin/dimension/update';
export const API_ADMIN_METRICS = 'admin/metrics';
export const API_ADMIN_METRIC_CREATE = 'admin/metric/create';
export const API_ADMIN_METRIC_DELETE = 'admin/metric/delete';
export const API_ADMIN_METRIC_ID = 'admin/metric/:id';
export const API_ADMIN_METRIC_UPDATE = 'admin/metric/update';
export const API_ADMIN_CUSTOM_PACKAGE_UPGRADE = 'admin/custom-package/upgrade';
export const API_ADMIN_CUSTOM_PACKAGE_SWITCH = 'admin/custom-package/switch';
export const API_ADMIN_ACCOUNTS_REQUEST_DATA = 'admin/account/get-all-data/:id';
export const ADMIN_UPDATE_ACCOUNT_SANDBOX_FREE = 'admin/update-account-sandbox-free';
export const ADMIN_COMPANY_MATCHES = 'admin/company-matches';
export const ADMIN_ACCOUNT_COMPANY_MATCHES = 'admin/account/company-matches';
export const ADMIN_CONTRACT_PLAN_MATCHES = 'admin/contract-plan/company-matches';
export const ADMIN_CONTRACT_PLAN_DATA_MONTHLY = 'admin/contract-plan/data-monthly';
export const ADMIN_CONTRACT_PLAN_ACCOUNT_DATA_MONTHLY = 'admin/contract-plan/account/data-monthly';
export const ADMIN_UPDATE_ACCOUNT_DEFAULT_RULE_TRIGGER = 'admin/update-account-default-rule-trigger';
export const ADMIN_UPDATE_FORM_VISIBLE_AND_FORM_START = 'admin/update-form-visible-and-form-start';
export const ADMIN_EXTERNAL_DATA = 'admin/external-data/';
export const ADMIN_CREATE_TABLE_BIGQUERY = 'admin/create-channels-bigquery';
export const ADMIN_USER_SOURCE_OLD_ACCOUNT = 'admin/custom-user-source-old-data';
export const ADMIN_UPDATE_ECOMMERCE = 'dev/rule-ecommerce';
export const ADMIN_UPDATE_CONVERSION_VALUE = 'admin/update-conversion-value';
export const ADMIN_REMOVE_CONVERSION_VALUE_INVALID = 'admin/remove-conversion-value-invalid';
export const ADMIN_MIGRATE_CRAWLER = 'admin/migrate-crawler';
export const ADMIN_ACCOUNT_PUBLISH = 'admin/accounts/publish';
export const ADMIN_ACCOUNT_FORM_INVENTORY = 'admin/forms-inventory/:accountId';
export const ADMIN_ACCOUNT_UPDATE = '/admin/account/update';
export const API_ADMIN_ACCOUNT_SAVED_REPORT = 'admin/account/saved-report';
export const API_ADMIN_NOTICE_ACTION = 'admin/notice-action';

// New
export const ADMIN_TESTS = 'admin/tests/';
export const ADMIN_QUESTIONS = 'admin/questions/';
export const ADMIN_CERTIFICATES = 'admin/certificates/';
export const ADMIN_LESSONS = 'admin/lessons/';
export const ADMIN_COURSES = 'admin/courses/';
export const ADMIN_SECTIONS = 'admin/sections/';
export const ADMIN_COUPONS = 'admin/coupons/';
export const ADMIN_PARTNERS = 'admin/partners/';
export const ADMIN_OFFICE_HOUR = 'admin/office-hours/';
export const ADMIN_USER_PARTNERS = 'admin/user-partners/';
export const ADMIN_USER_COURSE = 'admin/user-course/';
export const ADMIN_GOOGLE_CALENDAR_AUTH_URL = 'admin/google-calendar/auth-url/';
export const ADMIN_GOOGLE_CALENDAR_TOKEN = 'admin/google-calendar/token/';
export const ADMIN_GOOGLE_CALENDAR = 'admin/google-calendar';
export const ADMIN_GOOGLE_CALENDAR_SET_ID = 'admin/google-calendar/set-id';

// Client
export const CLIENT_PACKAGE = 'client/package/';
export const API_UPDATE_SCHEDULE_DEMO = '/client/account/scheduleUpgrade';
export const API_UPDATE_ACCOUNT_COMPANY_STATUS = '/client/account/companyStatus';
export const CLIENT_LISTENER_ACTIVE = 'client/listener/active/';
export const CLIENT_LISTENER = 'client/listener/';
export const CLIENT_LISTENER_ENABLE = 'client/listener/enable/';
export const CLIENT_LISTENER_DISABLE = 'client/listener/disable/';
export const CLIENT_LISTENER_ENABLE_ALL_FEATURES = 'client/listener/enable/all-features/';
export const API_CLIENT_ACCOUNTS = 'client/accounts/';
export const UPDATE_DOMAIN_ACCOUNT = 'client/account/domains/update';
export const API_CLIENT_ACCOUNT_UPGRADE = 'client/account-upgrade/';
export const CLIENT_ADD_ACCOUNT = 'client/add-account/';
export const CLIENT_ALL_DOMAIN = 'client/account/domains/';
export const API_CLIENT_REMOVE_DOMAIN = 'client/account/domains/remove/';
export const ACCOUNT_PACKAGE = 'client/account-package/';
export const API_CLIENT_CHANGE_ACCOUNT_NAME = 'client/change-account-name/';
export const API_CLIENT_FEATURE_CONFIG = 'client/feature/config/';
export const API_CLIENT_FEATURE_ENABLE = 'client/feature/enable/';
export const API_CLIENT_FEATURE_DISABLE = 'client/feature/disable/';
export const API_CLIENT_FEATURE_SUPPORT = 'client/feature/config-support';
export const ADD_USER = 'client/account/add-user-manager';
export const GET_USERS = 'client/account/get-users';
export const CHANGE_OWNER = 'client/account/change-owner';
export const REMOVE_USER_MANAGER = 'client/account/remove-user-manager';
export const CANCEL_OWNERSHIP_TRANFER = 'client/account/cancel-ownership-tranfer/';
export const COMPLETE_OWNERSHIP_TRANFER = 'client/account/complete-ownership-tranfer/';
export const API_UPDATE_CUSTOM_VARIABLES = 'client/custom-variable/update/';
export const API_CLIENT_CUSTOM_VARIABLE = 'client/custom-variable/';
export const API_CLIENT_CUSTOM_VARIABLE_IN_RULES = 'client/custom-variable-check-in-rules/';
export const API_CLIENT_EVENT_ACCOUNT = 'client/event-account/';
export const API_UPDATE_EVENT_ACCOUNT = 'client/event-account/update/';
export const API_CLIENT_DATALAYER_VARIABLE = 'client/datalayer-variable/';
export const API_UPDATE_DATALAYER_VARIABLE = 'client/datalayer-variable/update/';
export const API_CLIENT_SELECTOR_VARIABLE = 'client/selector-variables/';
export const API_UPDATE_SELECTOR_VARIABLE = 'client/selector-variables/update/';
export const API_CLIENT_EVENT_ACCOUNT_RULE = 'client/event-account-rule/';
export const API_CLIENT_EVENT_NATIVE_ACCOUNT_RULE = 'client/event-native-account-rule/';
export const API_CLIENT_NATIVE_BROWSER_STORAGE_ACCOUNT_RULE = 'client/native-browser-storage-account-rule/';
export const API_CLIENT_DATALAYER_VARIABLE_RULE = 'client/datalayer-variable-rule/';
export const API_CLIENT_SELECTOR_VARIABLE_RULE = 'client/selector-variable-rule/';
export const API_GET_VERSIONS = 'client/version/';
export const API_GET_HISTORIES = 'client/version/histories/';
export const API_VERSION_PUBLISH = 'client/version/publish';
export const API_VERSION_WAITING = 'client/version/version-waiting';
export const API_CLIENT_RULES = 'client/rules/';
export const API_CLIENT_ALL_RULES = 'client/rules/all/:accountId';
export const API_CLIENT_CHECK_RULE_BEFORE_SAVE = 'client/rule/check-rule-before-save/';
export const API_CLIENT_RULE_ADD = 'client/rule/add/';
export const API_CLIENT_RULE_UPDATE = 'client/rule/update/';
export const API_CLIENT_RULE_UPDATE_STATUS = 'client/rule/update-status/';
export const API_CLIENT_RULE_REVERT = 'client/rule/revert/';
export const API_CLIENT_RULE_REVERT_DEFAULT = 'client/rule/revert-to-default/';
export const API_CLIENT_RULE_REVERT_DEFAULT_ALL = 'client/rule/revert-to-default-all/';
export const API_CLIENT_RULE = 'client/rule/';
export const API_CLIENT_REMOVED_RULES = 'client/rules-remove/';
export const API_CLIENT_INVOICES = 'client/invoices/';
export const API_CLIENT_INVOICE_CHECKING = 'client/invoices/check-account-pastdue/';
export const API_CLIENT_SAVE_CARD = 'client/account/save-card/';
export const API_CLIENT_SAVE_INVOICE_INFORMATION = 'client/account/save-invoice-information/';
export const API_CLIENT_REPORTING = 'client/reporting-event/';
export const API_ALL_EVENT_NAME = 'client/feature-code/';
export const API_ALL_EVENT_LISTENERS = 'client/listener-code/';
export const API_CLIENT_SAVE_CARD_TO_UPGRADE = 'client/account/save-card-to-upgrade/';
export const API_CLIENT_SAVE_CARD_TO_DOWNGRADE = 'client/account/save-card-to-downgrade/';
export const API_CLIENT_CONNECT_TO_GOOGLE = 'client/connect-to-google/';
export const API_CLIENT_ACCOUNT_DOWNGRADE = 'client/account-downgrade/';
export const API_CLIENT_FEATURE_CONFIG_MY_VALUE = 'client/feature/config-my-value/';
export const API_CLIENT_REORDER_RULES = 'client/rule/update-order';
export const API_CLIENT_ACCOUNT = 'client/account/';
export const API_CLIENT_CUSTOM_AUTO_VARIABLE = 'client/custom-auto-variable/'; // Get automatic variables & custom/browser variables
export const API_USER_UNSUBSCRIBE_GROUPS = 'sendgrid/get-suppressions/';
export const API_USER_SUBSCRIBE = 'sendgrid/subscribe/';
export const API_USER_UNSUBSCRIBE = 'sendgrid/unsubscribe/';
export const API_CLIENT_FEATURE_GET_PROGRESS_THRESHOLD = 'client/feature/get-progress-thresholds';
export const API_CLIENT_FEATURE_UPDATE_PROGRESS_THRESHOLD = 'client/feature/update-progress-thresholds/';
export const API_CLIENT_FEATURE_REMOVE_PROGRESS_THRESHOLD = 'client/feature/remove-progress-thresholds/';
export const API_CLIENT_REPORTING_EVENT_TABLE = 'client/reporting-event-table/';
export const API_CLIENT_REPORTING_CUSTOM_VARIABLE = 'client/reporting-custom-variable/';
export const API_CLIENT_REPORTING_SELECTOR_VARIABLE = 'client/reporting-selector-variable/';
export const API_CLIENT_REPORTING_ACCOUNT_EVENT_TABLE = 'client/reporting-account-event-table/';
export const API_CLIENT_REPORTING_ACCOUNT_TOTAL_VISITOR_SESSION = 'client/get-total-event-table/';
export const API_CLIENT_FEATURE_GET_ACROSS_DOMAIN = 'client/feature/get-accross-domain';
export const API_CLIENT_FEATURE_UPDATE_ACROSS_DOMAIN = 'client/feature/update-accross-domain/';
export const API_CLIENT_FEATURE_REMOVE_ACROSS_DOMAIN = 'client/feature/remove-accross-domain/';
export const API_CLIENT_CHECK_EVENT_NATIVE_IN_RULES = 'client/feature/event-native-in-rules/';
export const API_CLIENT_CHECK_NATIVE_BROWSER_STORAGE_IN_RULES = 'client/feature/native-browser-storage-in-rules/';
export const API_CLIENT_FEATURE_CONFIG_EVENT_NATIVE = 'client/feature/config-event-native/';
export const API_CLIENT_EVENT_VARIABLE = 'client/event-variable/';
export const API_CLIENT_FEATURE_CONFIG_REMOVE_NATIVE_BROWSER_STORAGE_SESSION = 'client/feature/config-remove-native-browser-storage/';
export const API_CLIENT_FEATURE_CONFIG_NATIVE_BROWSER_STORAGE = 'client/feature/config-native-browser-storage/';
export const API_CLIENT_REPORTING_EVENT_VARIABLE = 'client/reporting-event-variable/';
export const API_CLIENT_FEATURE_CONFIG_SELECTOR = 'client/feature/config-selector/';
export const API_CLIENT_FEATURE_CONFIG_REMOVE_SELECTOR = '/client/feature/config-remove-selector/';
export const API_CLIENT_FEATURE_CONFIG_CLICK_ID_SETTINGS = 'client/feature/config-click-id-settings/';
export const API_CLIENT_FEATURE_RESET_CONFIG_CLICK_ID_SETTINGS = 'client/feature/config-click-id-settings/reset';
export const API_CLIENT_TERM_AND_CONDITION_PUBLISH = 'client/term-publish';
export const API_CLIENT_USER_TERMS = 'client/user-terms/';
export const API_CLIENT_ADD_USER_TERM = 'client/user-term/';
export const API_CLIENT_UPDATE_CONSOLE_STATUS = '/client/account/console-status/';
export const API_CLIENT_TOGGLE_ALLOW_ACCESS = 'client/allow-access/';
export const API_CLIENT_TICKETS = 'client/tickets';
export const API_CLIENT_TICKETS_NUMBER_COMMENT = 'client/tickets/number-comments';
export const API_CLIENT_TICKET = 'client/ticket/';
export const API_ADD_COMMENT = 'add-comment';
export const API_CLIENT_FIND_USER_ACCOUNT = 'client/find-user-account/';
export const API_CLIENT_UPDATE_FIRST_LOGIN_ACCOUNT = 'client/update-first-login-account/';
export const API_CLIENT_GET_COURSE = 'client/get-course';
export const API_CLIENT_BUY_COURSE = 'client/buy-course';
export const API_CLIENT_USER_COURSE = 'client/user-course';
export const API_CLIENT_GET_LESSON = 'client/get-lesson';
export const API_CLIENT_VIDEO_PROGRESS = 'client/video-progress';
export const API_CLIENT_USER_ANSWER = 'client/user-anwser';
export const API_CLIENT_REDEEM_COUPON = 'client/redeem-coupon';
export const API_CLIENT_SUBSCRIBE_PARTNER = 'client/subscribe-partner';
export const API_CLIENT_USER_PARTNER = 'client/user-partner'; // Get partner's application form data
export const API_CLIENT_CERTIFICATE = 'client/certificate'; // client/certificate/:id
export const API_USER_UPDATE_CARD = 'user/update-card';
export const API_CLIENT_OFFICE_HOURS = 'client/office-hours';
export const API_CLIENT_OFFICE_HOURS_BUY_CREDITS = 'client/office-hours/buy-credits';
export const API_CLIENT_OFFICE_HOURS_CONVERT_CREDITS = 'client/office-hours/convert-credits';
export const API_CLIENT_OFFICE_HOURS_CANCEL = 'client/office-hours/cancel';
export const API_CLIENT_ACCOUNT_COUPONS = 'client/account-coupons'; // client/account-coupons/:accountId
export const API_CLIENT_PARTNER = 'client/partner';
export const API_CLIENT_FREE_HOURS = 'client/free-hours';
export const API_CLIENT_CALENDAR_TIMEZONE = 'client/calendar-timezone';
export const API_CLIENT_SESSION_LENGTH = 'client/session-length';
export const API_CLIENT_DAYS_LENGTH = 'client/days-length';
export const API_CLIENT_FEATURE_UPDATE_USERWEATHER = 'client/user-weather';
export const API_CLIENT_FEATURE_UPDATE_USER_WEATHER_V2 = 'client/v2/user-weather';
export const API_CLIENT_FEATURE_UPDATE_SCROLL_DEPTH = 'client/scroll-depth';
export const API_CLIENT_LISTENER_SETTINGS = 'client/listener-settings';
export const API_CLIENT_CUSTOM_FORM_TARGET_RULES = 'client/custom-form/target-rules';
export const API_CLIENT_ELEMENT_CLICK_TARGET_RULES = 'client/element-click/target-rules';
export const API_CLIENT_FEATURE_SUPPORT_WOO = 'client/woo-support';
export const API_CLIENT_FEATURE_ASK_FOR_PRECISE_LOCATION = 'client/ask-for-precise-location';
export const API_CLIENT_FEATURE_ASK_FOR_PRECISE_LOCATION_V2 = 'client/v2/ask-for-precise-location';
export const API_CLIENT_EVENT_TIMESTAMPS = 'client/event-account/account-timestamp';
export const API_CLIENT_LISTENER_FOR_ROUTES = 'client/listener-for-routes';
export const API_CLIENT_ACCOUNT_UPDATE_TIMESTAMP = '/client/account/update-timestamps';
export const API_CLIENT_ACCOUNT_NOTIFICATIONS = 'client/notifications/account';
export const API_CLIENT_UPDATE_NOTIFICATION = 'client/notification';
export const API_SAVE_NOTIFICATION_PUSHER_TO_REDIS = 'client/notifications/save-pusher-to-redis';
export const GET_INVOICE = 'client/account-invoice/';
export const GET_INVOICE_UPCOMING = 'client/account-invoice-upcoming/';
export const API_CLIENT_ACCOUNT_INFO = 'client/account/info';
export const API_CLIENT_USER_INFO = 'client/user-info';
export const API_CLIENT_ACCOUNT_DOMAIN_VERIFICATION = 'client/account/domain-verification';
export const API_CLIENT_LISTENERS = 'client/listeners';
export const API_CLIENT_LISTENER_REQUEST = 'client/listener/request';
export const API_CLIENT_ACCOUNT_ENABLE_EVENT_LISTENERS = 'client/account/enable-event-listeners';
export const API_CLIENT_ACCOUNT_DISABLE_EVENT_LISTENERS = 'client/account/disable-event-listeners';
export const API_CLIENT_ACCOUNT_COMPLETED_STEPS = 'client/account/completedSteps';
export const API_CLIENT_ACCOUNT_COMPLETED_SETUP_GUIDE = 'client/account/completedSetupGuide';
export const API_CLIENT_ACCOUNT_COMPLETED_CHILD_SETUP_GUIDE = 'client/account/completedChildSetupGuide';
export const API_CLIENT_ACCOUNT_SETUP_GUIDE_DATA = 'client/account/setup-guide-data';
export const API_CLIENT_ACCOUNT_COMPLETE_TOUR = 'client/account/complete-tour';
export const API_CLIENT_ACCOUNT_GIVE_BILLING_RIGHTS = 'client/account/give-billing-rights';
export const API_CLIENT_NOTIFICATIONS = 'client/notifications';
export const API_CLIENT_VALIDATE_SCRIPT_ON_DOMAIN = 'client/account/validate-script-on-domain';
export const API_UPDATE_CONSENT_LISTENER = 'client/consent-listener/update';
export const API_CONSENT_RULES = 'client/consent-rules/';
export const API_CONSENT_RULE = 'client/consent-rule/';
export const API_CLIENT_PREVIEW = 'client/preview';
export const API_CLIENT_ACCOUNT_RULES = 'client/account-rules';
export const API_CLIENT_ACCOUNT_RULE = 'client/account-rule';
export const API_CLIENT_ACCOUNT_DESTINATIONS = 'client/destinations/';
export const API_CLIENT_ACCOUNT_MULTI_DESTINATION = 'client/multi-destinations/';
export const API_CLIENT_DESTINATIONS_GTM_TOKEN = 'client/destinations/gtm/token';
export const API_CLIENT_DESTINATIONS_GTM_CONTAINERS = 'client/destinations/gtm/containers';
export const API_CLIENT_DESTINATIONS_GTM_CHANGES = 'client/destinations/gtm/changes';
export const API_CLIENT_DESTINATIONS_GTM_CHANGES_LOG = 'client/destinations/gtm/changes-log';
export const API_CLIENT_DESTINATIONS_GTM_VARIABLES_IN_LL = 'client/destinations/gtm/variables-in-LL';
export const API_CLIENT_DESTINATIONS_GTM_TRIGGERS_IN_LL = 'client/destinations/gtm/triggers-in-LL';
export const API_CLIENT_DESTINATIONS_GTM_TAGS_IN_LL = 'client/destinations/gtm/tags-in-LL';
export const API_CLIENT_DESTINATIONS_GTM = 'client/destinations/gtm';
export const API_CLIENT_DESTINATIONS_GTM_CHANGELOG = 'client/destinations/gtm/changelog';
export const API_CLIENT_ACCOUNT_DESTINATION = 'client/destination/';
export const API_CLIENT_ACCOUNT_DESTINATION_ECOMMERCE = 'client/destination/ecommerce/';
export const API_CLIENT_DESTINATIONS_GTM_BY_RULE = 'client/destinations/gtm-by-rule';
export const API_CLIENT_DESTINATIONS_GTM_PUBLISH_OBJECT = 'client/destinations/gtm/publish-object';
export const API_CLIENT_FEATURE_ASK_GEOLOCATION_UPDATE = 'client/feature/ask-geolocation';
export const API_CLIENT_DESTINATIONS_GTM_VARIABLE_REFERENCES = 'client/destinations/gtm/variable/references';
export const API_CLIENT_DESTINATIONS_GTM_TRIGGER_REFERENCES = 'client/destinations/gtm/trigger/references';
export const API_CLIENT_DESTINATIONS_GTM_ACCOUNTS = 'client/destinations/gtm/accounts';
export const API_CLIENT_PERSONAL_DATA = 'client/personal-data/:accountId';
export const API_CLIENT_UPDATE_PERSONAL_DATA = 'client/personal-data/update/:accountId';
export const API_CLIENT_ADD_DATA_REQUEST = 'client/data-request';
export const API_CLIENT_DESTINATIONS_CUSTOM = 'client/destinations/custom';
export const API_CLIENT_CHECK_RELATED_BEFORE_DISABLE_EVENT = 'client/check-related-before-disable-event';
export const API_CLIENT_ACTIVE_DORMANT_ACCOUNT = 'client/account/active-dormant-account';
export const API_CLIENT_CANCEL_ACCOUNT = 'client/account/cancel-account';
export const API_CLIENT_REPORTING_EXPLORER = 'client/reporting-explorer';
export const API_CLIENT_REPORTING_EXPLORER_PROFILE = 'client/reporting-explorer/:reportName';
export const API_CLIENT_REPORT_TOOL = 'client/report-tool';
export const API_CLIENT_REPORTING_SYSTEM = 'client/reporting-system';
export const API_CLIENT_EXPORT_REPORTING_EXPLORER = 'client/export-reporting';
export const API_CLIENT_REPORTING_CHART = 'client/reporting-chart';
export const API_CLIENT_REPORTING_CONVERSION_NAME = 'client/report-conversion-name';
export const API_CLIENT_REPORTING_UTM_LIST = 'client/report-utm-list';
export const API_EVENT_PREVIEW = 'client/event-preview';
export const API_CLIENT_FORM_CATEGORIES = 'client/forms-categories/:accountId';
export const API_CLIENT_FORM_REQUIRING = 'client/forms-requiring-parent/:accountId';
export const API_CLIENT_FORM_IGNORED = 'client/forms-ignored/:accountId';
export const API_CLIENT_FORM_INVENTORY = 'client/form-category-inventory/:accountId';
export const API_CLIENT_FETCH_FORM_INVENTORY = 'client/forms-inventory/:accountId';
export const API_CLIENT_FETCH_FORM_INVENTORY_LISTENER = 'client/forms-inventory-listener/:accountId';
export const API_CLIENT_FETCH_CHILD_FORM_INVENTORY = 'client/forms-inventory-child/:parentId';
export const API_CLIENT_FETCH_CHILD_FORM_REQUIRING = 'client/forms-requiring-child/:parentId';
export const API_CLIENT_FETCH_CHILD_FORM_IGNORED = 'client/forms-ignored-child/:parentId';
export const API_CLIENT_COUNT_FORM_REQUIRING = 'client/count-form-category-requiring/:accountId';
export const API_CLIENT_COUNT_FORM_IGNORED = 'client/count-form-categories-isIgnored/:accountId';
export const API_CLIENT_COUNT_FORM_INVENTORY = 'client/count-form-category-inventory/:accountId';
export const API_CLIENT_UPDATE_FORM_CATEGORIES = 'client/forms-categories/update';
export const API_CLIENT_IGNORED_FORM_CATEGORIES = 'client/forms-categories/ignore';
export const API_CLIENT_CHILD_FORM_INHERIT_PARENT = 'client/inherit-parent-all';
export const API_CLIENT_SAVE_DRAFT_FORM_CATEGORIES = 'client/forms-categories/save-draft';
export const API_CLIENT_GET_CUSTOM_CATEGORIES = 'client/custom-categories/:accountId';
export const API_CLIENT_CHECK_CUSTOM_CATEGORIES = 'client/custom-categories-used';
export const API_CLIENT_UPDATE_CUSTOM_CATEGORIES = 'client/custom-categories/update';
export const API_CLIENT_DELETE_CUSTOM_CATEGORIES = 'client/custom-category/delete';
export const API_CLIENT_GET_PUSH_EVENTS = 'client/account/account-is-push-events/:accountId';
export const API_CLIENT_UPDATE_PUSH_EVENTS = 'client/account/account-update-push-events';
export const API_CLIENT_UPDATE_INHERIT_PARENT = 'client/account/account-update-inherit-parent';
export const API_CLIENT_GET_LISTENER_FORMS_ENABLED = 'client/listener/enabled-forms';
export const API_CLIENT_UPDATE_CURRENT_STEP_CATEGORIES = 'client/account/account-step-category';
export const API_CLIENT_UPDATE_CURRENT_STEP_CUSTOM_FORMS = 'client/account/account-step-custom-form';
export const API_CLIENT_UPDATE_CURRENT_STEP_CONVERSION = 'client/account/account-step-conversion';
export const API_CLIENT_UPDATE_IS_VIEW_DOMAIN_CUSTOM_FORMS = 'client/account/account-view-domain-custom-form';
export const API_RECORD_PROFILE_EVENT = 'client/record-profile/event';
export const API_RECORD_PROFILE_EVENT_E_COMMERCE = 'client/record-profile/event-ecommerce';
export const API_RECORD_PROFILE_SESSION = 'client/record-profile/session';
export const API_RECORD_PROFILE_USER = 'client/record-profile/user';
export const API_RECORD_PROFILE_PERSON = 'client/record-profile/person';
export const API_RECORD_PROFILE_COMPANY = 'client/record-profile/company';
export const API_RECORD_PROFILE_TARGET_ACCOUNT = 'client/record-profile/target-account';
export const API_RECORD_PROFILE = 'client/record-profile';
export const API_RECORD_PROFILE_TABLE_STEP_FORM = 'client/record-profile/table-step-form';
export const API_RECORD_PROFILE_CHART_FORM_MULTI_STEP = 'client/record-profile/chart-form-multi-step';
export const API_RECORD_PROFILE_CHART_FORM_ACTIVITY = 'client/record-profile/chart-form-activity';
export const API_CLIENT_ACCOUNT_DESTINATION_SWITCH_TYPE = 'client/destination/switch-type';
export const API_CLIENT_DESTINATIONS_GTM_CHANGES_SWITCH_CLIENT_TO_SERVER = 'client/destinations/gtm/changes/switch-client-to-server';
export const API_CLIENT_DESTINATIONS_GTM_CHANGES_SWITCH_SERVER_TO_CLIENT = 'client/destinations/gtm/changes/switch-server-to-client';
export const API_CLIENT_CUSTOM_REPORTS = 'client/custom-reports/:accountId';
export const API_CLIENT_CUSTOM_REPORT = 'client/custom-report/:accountId/:id';
export const API_CLIENT_CREATE_CUSTOM_REPORT = 'client/custom-report/create';
export const API_CLIENT_DELETE_CUSTOM_REPORT = 'client/custom-report/delete';
export const API_CLIENT_UPDATE_CUSTOM_REPORT = 'client/custom-report/update';
export const API_CLIENT_SHARE_CUSTOM_REPORT = 'client/custom-report/share';
export const API_CLIENT_SCOPES = 'client/scopes/:accountId';
export const API_CLIENT_DIMENSIONS = 'client/dimensions/:accountId';
export const API_CLIENT_METRICS = 'client/metrics/:accountId';
export const API_CLIENT_DIMENSION = 'client/dimension';
export const API_CLIENT_DIMENSION_DETAIL = 'client/dimension/:accountId/:id';
export const API_CLIENT_DIMENSION_EXAMPLES = 'client/dimension/examples/:accountId';
export const API_CLIENT_FETCH_FEW_FORMS = 'client/forms-categories-few-forms/:accountId';
export const API_CLIENT_FETCH_TRIGGER_CONVERSION = 'client/destinations/conversions-default/:accountId';
export const API_CLIENT_BUILD_WEB_CONVERSION = 'client/rule/conversions';
export const API_CLIENT_ACCOUNT_BEFORE_CATEGORY = 'client/account-rule/before-removes';
export const API_CLIENT_UPDATE_REVEAL_PEOPLE = 'client/forms-categories/reveal-people';
export const API_CLIENT_UPDATE_IS_REDIRECT = 'client/forms-categories/isRedirect';
export const API_CLIENT_NUMBER_PAGES_CRAWL = 'client/number-pages-crawled/';
export const API_CLIENT_RECORD_PROFILE_TABLE_GROUP = 'client/record-profile/:tableType';
export const API_CLIENT_EXTERNAL_DATA = 'client/external-data';
export const API_CLIENT_EXTERNAL_UPDATE_DATA = 'client/external-data/update';
export const API_CLIENT_CONNECT_LOGIN = 'client/external-data/connect-login';
export const API_CLIENT_CONNECT_LOGIN_CALLBACK = 'client/external-data/connect-login-callback';
export const API_CLIENT_SAVE_RULE_MONITORING_CATEGORY = 'client/notifications/form-categories-monitoring';
export const API_CLIENT_GET_RULE_MONITORING_CATEGORY = 'client/notifications/get-form-categories-monitoring';
export const API_CLIENT_CHANNELS = 'client/channels/:accountId';
export const API_CLIENT_CHANNELS_DETAIL = 'client/channel/:id';
export const API_CLIENT_CREATE_CHANNEL = 'client/channel/create';
export const API_CLIENT_UPDATE_CHANNEL = 'client/channel/update';
export const API_CLIENT_DELETE_CHANNEL = 'client/channel/delete';
export const API_CLIENT_UPDATE_STATUS_CHANNEL = 'client/channel/enable';
export const API_CLIENT_CHANNEL_USER_SOURCES = 'client/channel/user-source/:accountId';
export const API_CLIENT_USER_SOURCES = 'client/user-source/:accountId';
export const API_CLIENT_UPDATE_USER_SOURCE = 'client/user-source/update';
export const API_CLIENT_DELETE_USER_SOURCE = 'client/user-source/delete';
export const API_CLIENT_CHECK_USER_SOURCE = 'client/check-user-source';
export const API_CLIENT_GET_MAX_STEP_FORM_CATEGORY = 'client/get-max-step-form-category';
export const API_GET_ECO_ITEM_CATEGORIES = 'client/get-item-categories';
export const API_CLIENT_REPORT_FILTER_CHECKLIST = 'client/report-filter-checklist';
export const API_CLIENT_ACCOUNT_RULE_CHART = 'client/account-rule-chart';
export const API_CLIENT_EXTERNAL_DATA_CONNECTION = 'client/external-data-connections';
export const API_CLIENT_EXTERNAL_DATA_CONNECTION_GET_CONNECTIONS = 'client/external-data-connections/:type/:accountId';
export const API_CLIENT_ACCOUNT_CRAWLER_STATUS = 'client/account/crawler-status/:accountId';
export const API_CLIENT_GET_ALL_CONVERSION = 'client/all-conversion/:accountId';
export const API_CLIENT_GET_COMPANY_INFO_CHANGE_OWNER = 'client/get-company-info';
export const API_CLIENT_GET_SEGMENT_OPTION = 'client/segment/option';
export const API_CLIENT_GET_LIST_SEGMENT = 'client/segments/:accountId';
export const API_CLIENT_SEGMENT_DETAil = 'client/segment/:id';
export const API_CLIENT_SEGMENT = 'client/segment';
export const CLIENT_FORMS_CATEGORIES_LIST_LISTENER_FORM = 'client/forms-categories-list-listener-form/:accountId';
export const CLIENT_FORMS_CATEGORIES_LIST_LISTENER_CUSTOM_FORM = 'client/forms-categories-list-listener-custom-form/:accountId';
export const API_CLIENT_GET_LIST_SEGMENT_REPORT = 'client/segment-report/:accountId';
export const API_CLIENT_SEGMENT_REPORT = 'client/segment-report';
export const API_CLIENT_SEGMENT_CHART = 'client/segment-chart';
export const API_CLIENT_GET_DEFAULT_REPORT_CONFIGS = 'client/report-configs/segment-sticky/:accountId';
export const API_CLIENT_DEFAULT_REPORT_SEGMENT_STICKY_CONFIGS = 'client/report-configs/segment-sticky';
export const API_CLIENT_DEFAULT_REPORT_DATE_RANGE_CONFIGS = 'client/report-configs/date-range-config';
export const CLIENT_UPDATE_SEND_EVENT_TO_SERVER_SIDE = 'client/update-send-event-to-server-side';
export const CLIENT_SWITCH_SEND_EVENT_TO_SERVER_SIDE = 'client/switch-send-event-to-server-side';
export const CLIENT_PDF_INVOICE = 'client/pdf-invoice/:id';
export const CLIENT_SS_TRIGGER_LOGS = 'client/ss-trigger-logs';
export const CLIENT_DOWNLOAD_SS_TRIGGER_LOGS = 'client/download-trigger-logs';
export const CLIENT_ACCOUNT_LISTENER = 'client/account-listener/:accountId';

export const CLIENT_GET_SAVED_VIEWS = 'client/saved-views';
export const CLIENT_GET_SAVED_VIEW_DEFAULT = 'client/saved-view-default';
export const CLIENT_GET_SAVED_REPORT_BY_ID = 'client/saved-report-by-id';
export const CLIENT_GET_SAVED_REPORTS = 'client/saved-reports';
export const CLIENT_REPORT_VIEW = 'client/report-view';
export const CLIENT_SET_VIEW_DEFAULT = 'client/set-view-default';

export const API_CLIENT_GET_LIST_SEVER_SIDE_TRIGGER = 'client/server-side-trigger/:accountId';
export const API_CLIENT_SEVER_SIDE_TRIGGER_DETAIL = 'client/server-side-trigger-detail/:id';
export const API_CLIENT_SEVER_SIDE_TRIGGER = 'client/server-side-trigger';
export const API_CLIENT_GET_CHECKLIST_SEVER_SIDE_TRIGGER = 'client/check-list-property/:accountId';
export const API_CLIENT_GET_LIST_EXPORT_ALL_FORM = 'client/export-all-form-category/:accountId';
export const API_CLIENT_GET_LIST_EXPORT_FORM_NEEDING_CATEGORIZATION = 'client/export-form-needing-categorization/:accountId';
export const API_CLIENT_GET_LIST_EXPORT_ALREADY_CATEGORIZED = 'client/export-form-already-categorized/:accountId';
export const API_CLIENT_SEVER_SIDE_TRIGGER_PREVIEW = 'client/server-side-trigger-preview';

// Target Account
export const CLIENT_TARGET_ACCOUNT = 'client/target-account/';
export const CLIENT_GET_ACCOUNT_GROUPS = 'client/account-groups/';
export const CLIENT_GET_ACCOUNT_GROUP = 'client/account-group/';

//Sales conversion
export const API_CLIENT_GET_LIST_SALES_CONVERSION = 'client/sales-conversion/:accountId';
export const API_CLIENT_SALES_CONVERSION_DETAIL = 'client/sales-conversion-detail/:id';
export const API_CLIENT_SALES_CONVERSION = 'client/sales-conversion';
export const API_CLIENT_SALES_CONVERSION_CHART = 'client/sales-conversion-chart';
// Schedule Saved Report
export const CLIENT_SCHEDULE_SAVED_REPORT = 'client/schedule-saved-report';

// Goals
export const API_CLIENT_GOALS = 'client/goals';

//Send Code
export const SEND_CODE = 'send-code';
export const SEND_CODE_CHANGE_OWNER = 'send-code-change-owner';
export const API_SET_PASSWORD = 'set-password/';
export const API_SEND_CODE_UPDATE_EMAIL = 'send-code-update-email';
export const API_VERIFY_CODE_UPDATE_EMAIL = 'verify-code-update-email';
export const SEND_CODE_SET_PASSWORD = 'send-code-set-password/';

// Unsubscribe
export const UNSUBSCRIBE = 'sendgrid/unsubscribeEmail';

// Support
export const API_FIND_ACCOUNT = 'support/account';
export const API_FIND_TICKETS = 'support/tickets';
export const API_FIND_TICKET_DETAIL = 'support/tickets';
export const API_FIND_DATA_REQUEST_DETAIL = 'client/data-request';
export const API_SUPPORT_LOGIN_AS = 'support/login-as';
export const API_SUPPORT_EXIT = 'support/exit';
export const API_SUPPORT_ACCOUNT_DOMAIN_VERIFICATION = 'support/account/domain-verification';
export const API_GET_DATA_LISTENERS_FEATURES = 'get-data-listeners-features';

export const API_GOOGLE_CALENDAR_EVENTS_WATCH = 'google-calendar/events/watch';
export const API_GOOGLE_USER_INFO = 'https://openidconnect.googleapis.com/v1/userinfo';
export const API_GOOGLE_TOKEN_INFO = 'https://www.googleapis.com/oauth2/v2/tokeninfo';

// GTM APIs
// https://developers.google.com/tag-platform/tag-manager/api/v2/reference
export const API_GTM_USER_PERMISSIONS = 'https://www.googleapis.com/tagmanager/v2/+parent/user_permissions'; // +parent example: accounts/{account_id}
export const API_GTM_ACCOUNTS = 'https://www.googleapis.com/tagmanager/v2/accounts';
export const API_GTM_CONTAINERS = 'https://www.googleapis.com/tagmanager/v2/+parent/containers'; // +parent example: accounts/{account_id}
export const API_GTM_WORKSPACES = 'https://www.googleapis.com/tagmanager/v2/+parent/workspaces'; // +parent example: accounts/{account_id}/containers/{container_id}
export const API_GTM_TAGS = 'https://www.googleapis.com/tagmanager/v2/+parent/tags'; // +parent example: accounts/{account_id}/containers/{container_id}/workspaces/{workspace_id}
export const API_GTM_FOLDERS = 'https://www.googleapis.com/tagmanager/v2/+parent/folders'; // +parent example: accounts/{account_id}/containers/{container_id}/workspaces/{workspace_id}
export const API_GTM_MOVE_ENTITIES_TO_FOLDER = 'https://www.googleapis.com/tagmanager/v2/+path:move_entities_to_folder'; // +path example: accounts/{account_id}/containers/{container_id}/workspaces/{workspace_id}/folders/{folder_id}
export const API_GTM_CREATE_VERSION = 'https://www.googleapis.com/tagmanager/v2/+path:create_version'; // +path example: accounts/{account_id}/containers/{container_id}/workspaces/{workspace_id}
export const API_GTM_PUBLISH = 'https://www.googleapis.com/tagmanager/v2/+path:publish'; // +path example: accounts/{account_id}/containers/{container_id}/versions/{version_id}
export const API_GTM_WORKSPACE_STATUS = 'https://www.googleapis.com/tagmanager/v2/+path/status'; // +path example: accounts/{account_id}/containers/{container_id}/workspaces/{workspace_id}
export const API_GTM = 'https://www.googleapis.com/tagmanager/v2/path';

// Geoplugin APIs
export const API_SSL_GEOPLUGIN_JSON = 'https://ssl.geoplugin.net/json.gp?k={{key}}';

const API_REPORTING_ACCOUNT_EVENT_TABLE_LAMBDA_URL = process.env.REACT_APP_EVENT_TABLE_LAMBDA_URL;
export { API_REPORTING_ACCOUNT_EVENT_TABLE_LAMBDA_URL };

// Salesforce
export const API_SALESFORCE_GET_OAUTH_URL = 'get-oauth-url';
export const API_SALESFORCE_GET_OAUTH_TOKEN = 'get-oauth-token';
export const API_SALESFORCE_GET_CONNECTION_LIST = 'connections';
export const API_SALESFORCE_CONNECTION = 'connection';
export const API_SALESFORCE_CONNECTION_DISCONNECT = 'connection/disconnect';
export const API_SALESFORCE_OBJECT= 'objects';
export const API_SALESFORCE_RULE = 'rule';
export const API_SALESFORCE_RULE_CANCEL = 'rule/cancel';
export const API_SALESFORCE_RULE_LIST = 'rules';
export const API_SALESFORCE_GET_JOB_LIST = 'jobs'
export const API_SALESFORCE_GET_CUSTOM_OBJECT = 'custom-object';
export const API_SALESFORCE_SELECT_FIELDS = 'select-fields';
export const API_SALESFORCE_GET_OBJECT_INFO = 'object';
export const API_SALESFORCE_GET_OBJECT_LIST = 'object-list';
export const API_SS_TRIGGER_GET_CUSTOM_OBJECT = 'get-rule/all-object';
export const API_SALESFORCE_GET_ALL_RULE = 'get-all-rule/:accountId';
export const API_SALESFORCE_GET_CHECKLIST_SEVER_SIDE_TRIGGER = 'get-check-list-property/:accountId';
export const API_SALESFORCE_CREATE_TRACK_HISTORY = 'create-track-history';