import CIcon from '@coreui/icons-react';
import { CButton, CForm } from '@coreui/react';
import { Formik } from 'formik';
import React, { Fragment, useContext, useEffect, useState } from 'react';
import Select, { components } from 'react-select';
import { toast } from 'react-toastify';
import { REPORT_DATA_TYPES, REPORT_NAMES, REPORT_TAB_TYPES, VARIABLE_OPERATORS_REPORT } from '../../../../constants/analytics';
import { EventExplorerContext } from './EventExplorer';
import CLabel from '../../../migration/CLabel';
import { rectIntersection, DndContext } from '@dnd-kit/core';
import { arrayMove, SortableContext, useSortable, rectSortingStrategy } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import SelectOption from './SelectOption';

const SortableMultiValueLabel = React.memo((props) => {
     const { attributes, listeners } = useSortable({ id: props.data.value });
     return (
          <div {...attributes} {...listeners}>
               <components.MultiValueLabel {...props} />
          </div>
     );
});

const SortableMultiValue = React.memo((props) => {
     const { isDragging, setNodeRef, transform, transition } = useSortable({
          id: props.data.value,
     });
     const style = transform
          ? {
               transform: CSS.Translate.toString(transform),
               transition: transition,
               pointerEvents: 'none',
               ...(isDragging
                    ? {
                         zIndex: 1,
                    }
                    : {}),
          }
          : undefined;
     return (
          <div className="sortable-multivalue" ref={setNodeRef} style={style}>
               <components.MultiValue {...props} />
          </div>
     );
});

const ChildMenu = ({ defaultValue, MenuList, handleChangeField, type, options, allOption = [], isMulti }) => {
     const {
          reportName,
          filterMetric,
          setFilterMetric,
          setFilterEvent,
          filterProperties,
          setFilterProperties,
          filterEvent,
          setIsFilterConversion,
          showData,
          isDirect,
          handleFindView,
          setIsDirect,
          setWeight,
          weight,
          setIsMapView,
          setIsApplyMetric,
          setIsHandleParams,
          scoped,
          reportType,
     } = useContext(EventExplorerContext);

     let { dimensionLength, requireDimensions } = reportType ? REPORT_DATA_TYPES[reportName][scoped] : REPORT_DATA_TYPES[reportName];
     const [selected, setSelected] = useState(defaultValue);
     const [isDragging, setIsDragging] = useState(false);
     const [menuIsOpen, setMenuIsOpen] = useState(false);

     useEffect(() => setSelected(defaultValue), [defaultValue]);

     const disableChange = reportName === REPORT_NAMES.ECOMMERCE_PURCHASE_FLOW;
     const handleSubmit = (values) => {
          if (!showData) {
               return;
          }
          if (
               (reportName === REPORT_NAMES.CONVERSION_SOURCE_ATTRIBUTION_REPORT || reportName === REPORT_NAMES.ECOMMERCE_ITEMS) &&
               [REPORT_TAB_TYPES.MODEL, REPORT_TAB_TYPES.DIMENSIONS].includes(type)
          ) {
               if (type === REPORT_TAB_TYPES.DIMENSIONS) {
                    setFilterEvent([{ type: '', value: '', id: '', operator: 'ct', optionsOperator: VARIABLE_OPERATORS_REPORT }]);
                    handleChangeField([values]);
               } else {
                    handleChangeField(values);
               }
          } else {
               switch (type) {
                    case REPORT_TAB_TYPES.DIMENSIONS:
                         break;
                    case REPORT_TAB_TYPES.PROPERTIES:
                         break;
                    case REPORT_TAB_TYPES.METRICS:
                         setIsFilterConversion(true);
                         break;

                    default:
                         break;
               }
               handleChangeField(values);
          }
          setIsHandleParams(false);
          switch (type) {
               case REPORT_TAB_TYPES.DIMENSIONS:
                    handleFindView(REPORT_TAB_TYPES.DIMENSIONS, values);
                    break;
               case REPORT_TAB_TYPES.PROPERTIES:
                    handleFindView(REPORT_TAB_TYPES.PROPERTIES, values);
                    break;
               case REPORT_TAB_TYPES.METRICS:
                    setIsApplyMetric(false);
                    handleFindView(REPORT_TAB_TYPES.METRICS, values);
                    break;
               case REPORT_TAB_TYPES.MODEL:
                    handleFindView(REPORT_TAB_TYPES.MODEL, { ...values, noneDirect: isDirect });
                    break;

               default:
                    break;
          }
     };

     const removeSelectMulti = disableChange
          ? {
               MultiValueRemove: () => null,
          }
          : {};
     const checkClearAll = (type) => {
          const propertyFilterValues = filterProperties.map((item) => item.value);
          const metricFilterValues = filterMetric.map((item) => item.value);

          if (
               (type === REPORT_TAB_TYPES.PROPERTIES && filterEvent.some((item) => propertyFilterValues.includes(item.type))) ||
               (type === REPORT_TAB_TYPES.METRICS && filterEvent.some((item) => metricFilterValues.includes(item.type)))
          )
               return true;

          return false;
     };
     const checkRemoveItem = (e, removedValue) => {
          if (
               reportName === REPORT_NAMES.FORM_CATEGORY_REPORT &&
               type === REPORT_TAB_TYPES.DIMENSIONS &&
               removedValue &&
               removedValue.value === 'formId'
          ) {
               return true;
          }

          if (e.length === 0 && type === REPORT_TAB_TYPES.DIMENSIONS) {
               return true;
          } else if (e.length > 0 && type === REPORT_TAB_TYPES.DIMENSIONS) {
               return false;
          }

          if (filterEvent.find((el) => el.type === removedValue.value)) {
               return true;
          }

          return false;
     };
     const handleClearAll = (setValues) => {
          if (!showData) return;

          if (checkClearAll(type)) {
               toast.error(`Can't remove ${type} because it exists in a filter.`);
               return;
          }

          if (type === REPORT_TAB_TYPES.PROPERTIES) {
               setValues([]);
               setFilterProperties([]);
          }
          if (type === REPORT_TAB_TYPES.METRICS) {
               setValues([]);
               setFilterMetric([]);
          }

          switch (type) {
               case REPORT_TAB_TYPES.PROPERTIES:
                    handleFindView(REPORT_TAB_TYPES.PROPERTIES, []);
                    break;
               case REPORT_TAB_TYPES.METRICS:
                    handleFindView(REPORT_TAB_TYPES.METRICS, []);
                    break;
               default:
                    break;
          }
     };
     const handleChangeDirect = (e) => {
          e.preventDefault();
          const noneDirect = !isDirect;
          setIsMapView(false);
          setIsDirect(noneDirect);
          setWeight({ ...weight, noneDirect: noneDirect });
          handleFindView(REPORT_TAB_TYPES.MODEL, { ...weight, noneDirect: noneDirect });
     };

     const handleDragStart = () => {
          setIsDragging(true);
          setMenuIsOpen(false);
     };

     const handleDragEnd = (event) => {
          const { active, over } = event;
          if (active.id !== over?.id) {
               setSelected((items) => {
                    const oldIndex = items.findIndex((i) => i.value === active.id);
                    const newIndex = items.findIndex((i) => i.value === over?.id);
                    return arrayMove(items, oldIndex, newIndex);
               });
          }
          setIsDragging(false);
     };

     const handleMenuOpen = () => {
          if (!isDragging) {
               setMenuIsOpen(true);
          }
     };

     const handleMenuClose = () => {
          setMenuIsOpen(false);
     };

     return (
          <>
               <Formik
                    initialValues={selected}
                    // validationSchema={validationSchema}
                    onSubmit={handleSubmit}
                    validateOnChange={false}
                    validateOnBlur={false}
                    enableReinitialize
               >
                    {({ values, handleSubmit, setValues }) => {
                         const handleChangeValue = (e, removedValue) => {
                              if (
                                   (reportName === REPORT_NAMES.CONVERSION_SOURCE_ATTRIBUTION_REPORT ||
                                        reportName === REPORT_NAMES.ECOMMERCE_ITEMS) &&
                                   [REPORT_TAB_TYPES.MODEL, REPORT_TAB_TYPES.DIMENSIONS].includes(type)
                              ) {
                                   setValues(e);
                                   setSelected(e);
                              } else {
                                   const selectItem = e || [];
                                   const findSelectAll = selectItem.find((item) => item.label === 'Select All');

                                   if (removedValue) {
                                        if (checkRemoveItem(selectItem, removedValue)) {
                                             if (type === REPORT_TAB_TYPES.DIMENSIONS) {
                                                  if (
                                                       reportName === REPORT_NAMES.FORM_CATEGORY_REPORT &&
                                                       type === REPORT_TAB_TYPES.DIMENSIONS &&
                                                       removedValue &&
                                                       removedValue.value === 'formId'
                                                  ) {
                                                       toast.error(`Can't remove FormID dimensions`);
                                                  } else {
                                                       toast.error(`Can't remove all dimensions`);
                                                  }
                                             } else {
                                                  toast.error(`Can't remove ${type} because it exists in a filter.`);
                                             }
                                             return;
                                        }
                                   }

                                   if (
                                        type === REPORT_TAB_TYPES.DIMENSIONS &&
                                        [REPORT_NAMES.BY_CONVERSION, REPORT_NAMES.BY_PROPERTY].includes(reportName)
                                   ) {
                                        if (requireDimensions && !e.some((el) => requireDimensions.some((item) => item?.key === el.value))) {
                                             toast.error(
                                                  `Dimensions must exist at least one dimension in the default dimensions " ${requireDimensions
                                                       .map((el) => el.label)
                                                       .join(', ')} ".`
                                             );
                                             return;
                                        }
                                        if (dimensionLength && e.length > dimensionLength) {
                                             toast.error(`Only ${dimensionLength} dimensions can be selected for this report.`);
                                             return;
                                        }
                                   }

                                   if (findSelectAll) {
                                        setValues(allOption);
                                        setSelected(allOption);
                                   } else {
                                        setValues(selectItem);
                                        setSelected(selectItem);
                                   }
                              }
                         };

                         return (
                              <CForm onSubmit={handleSubmit} noValidate>
                                   <div className="report-select">
                                        <div className="report-select-header">
                                             {[REPORT_TAB_TYPES.MODEL].includes(type) ? (
                                                  <p>Select the model</p>
                                             ) : (
                                                  <p>Select the columns to display in the table</p>
                                             )}
                                             <div className="d-flex align-items-center justify-content-end filter-report">
                                                  {!disableChange && ![REPORT_TAB_TYPES.DIMENSIONS, REPORT_TAB_TYPES.MODEL].includes(type) ? (
                                                       <CButton onClick={() => handleClearAll(setValues)}>
                                                            <CLabel>clear all</CLabel>
                                                            <CIcon icon="iconDeleteCircle" />
                                                       </CButton>
                                                  ) : (
                                                       ``
                                                  )}
                                                  {[REPORT_TAB_TYPES.MODEL].includes(type) ? (
                                                       <div className="filter-report">
                                                            <CButton
                                                                 className={` filter-button ${isDirect ? 'is-direct' : ''}`}
                                                                 onClick={(e) => handleChangeDirect(e)}
                                                            >
                                                                 <CLabel>Exclude Direct</CLabel>
                                                            </CButton>
                                                       </div>
                                                  ) : (
                                                       ``
                                                  )}
                                                  {![].includes(reportName) &&
                                                       (type === REPORT_TAB_TYPES.DIMENSIONS && options.length <= 1 && allOption.length <= 1
                                                            ? false
                                                            : true) ? (
                                                       <CButton
                                                            type="submit"
                                                            className={` filter-button ${JSON.stringify(values) === JSON.stringify(defaultValue) ? 'disabled' : ''
                                                                 }`}
                                                       >
                                                            <CLabel>Apply</CLabel>
                                                       </CButton>
                                                  ) : (
                                                       ``
                                                  )}
                                             </div>
                                        </div>

                                        <div className="events-multiselect">
                                             {[REPORT_TAB_TYPES.METRICS, REPORT_TAB_TYPES.PROPERTIES, REPORT_TAB_TYPES.DIMENSIONS].includes(type) && isMulti ? (

                                                  [REPORT_NAMES.BY_CONVERSION, REPORT_NAMES.BY_PROPERTY].includes(reportName)
                                                       ?
                                                       <>
                                                            <DndContext collisionDetection={rectIntersection} onDragStart={handleDragStart} onDragEnd={handleDragEnd}>
                                                                 <SortableContext items={selected.map((s) => s.value)} strategy={rectSortingStrategy}>
                                                                      <SelectOption
                                                                           isMulti
                                                                           name="key"
                                                                           placeholder="Select"
                                                                           value={values}
                                                                           className="text-dark "
                                                                           classNamePrefix="react-select"
                                                                           classNameWrapper=" report-selection"
                                                                           onChange={(e, removeItem) => handleChangeValue(e, removeItem)}
                                                                           options={options}
                                                                           selectInputType={false}
                                                                           isDragging={isDragging}
                                                                      />
                                                                 </SortableContext>
                                                            </DndContext>
                                                            {/* <DndContext collisionDetection={rectIntersection} onDragStart={handleDragStart} onDragEnd={handleDragEnd}>
                                                               </DndContext> */}

                                                       </>
                                                       :

                                                       <DndContext collisionDetection={rectIntersection} onDragStart={handleDragStart} onDragEnd={handleDragEnd}>
                                                            <SortableContext items={selected.map((s) => s.value)} strategy={rectSortingStrategy}>
                                                                 <Select
                                                                      className="text-dark"
                                                                      classNamePrefix="react-select"
                                                                      isMulti
                                                                      options={values.length === allOption.length ? [] : options}
                                                                      value={values}
                                                                      onChange={(e, item) => handleChangeValue(e, item.removedValue)}
                                                                      closeMenuOnSelect={false}
                                                                      components={{
                                                                           MenuList,
                                                                           MultiValue: SortableMultiValue,
                                                                           MultiValueLabel: SortableMultiValueLabel,
                                                                           ClearIndicator: null,
                                                                           ...removeSelectMulti,
                                                                      }}
                                                                      noOptionsMessage={() => <span>No options available.</span>}
                                                                      menuIsOpen={menuIsOpen}
                                                                      onMenuOpen={handleMenuOpen}
                                                                      onMenuClose={handleMenuClose}
                                                                 />
                                                            </SortableContext>
                                                       </DndContext>
                                             ) : (
                                                  <Select
                                                       classNamePrefix="react-select"
                                                       options={values.length === allOption.length ? [] : options}
                                                       value={values}
                                                       onChange={(e, item) => handleChangeValue(e, item.removedValue)}
                                                       isMulti={isMulti}
                                                       isClearable={true}
                                                       backspaceRemovesValue={true}
                                                       components={{ ClearIndicator: null, ...removeSelectMulti, MenuList }}
                                                       noOptionsMessage={() => <span>No options available.</span>}
                                                  />
                                             )}
                                        </div>
                                   </div>
                              </CForm>
                         );
                    }}
               </Formik>
          </>
     );
};

export default ChildMenu;
