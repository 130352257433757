import React, { useRef, useMemo, useCallback, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useParams, Link, NavLink } from 'react-router-dom';
import { CSidebar, CSidebarBrand, CSidebarNav, CLink, CBadge } from '@coreui/react';
import CIcon from '@coreui/icons-react';
import 'tippy.js/dist/tippy.css';
import { setFlexibleModal, setSidebarShow } from '../../actions/common';
import { setNewLayout, setPreviewBrowserRule } from '../../actions/subscriber';
import { getSimpleListenerNav, getActiveDestinationNav, getActiveGoals } from '../../helpers/cms/subscriber';
import { defaultSubscriberNavGroup, defaultSubscriberNewNavigation } from '../_nav/subscriber';
import { COMPONENT_NAME, NEW_NAVIGATION_PATH, SUBSCRIBER_PATH } from '../../constants';
import CCreateElement from '../../components/migration/CCreateElement';
import CToggler from '../../components/migration/CToggler';
import CSidebarNavDropdown from '../../components/migration/CSidebarNavDropdown';
import CSidebarNavItem from '../../components/migration/CSidebarNavItem';
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from '../../components/general/dropdown';
import SubscriberNewSidebarFooter from './TheNewSidebarFooter';

const CSidebarNavDropdownCustom = ({ name, className = '', children, show, toggleCustom, icon, path, nameButton, showButton, componentName }) => {
     const dispatch = useDispatch();
     const [showDropdown, setShowDropdown] = useState(show);
     const dropdownRef = useRef(null);

     useEffect(() => {
          setShowDropdown(show);
     }, [show]);

     useEffect(() => {
          if (showDropdown) {
               const sidebarElement = document.querySelector('.sidebar-items-wrap');
               const dropdownElement = dropdownRef.current;

               if (sidebarElement && dropdownElement) {
                    const dropdownItems = dropdownElement.querySelector('.sidebar-nav-dropdown-items');
                    const sidebarRect = sidebarElement.getBoundingClientRect();
                    const dropdownRect = dropdownElement.getBoundingClientRect();
                    const dropdownItemsRect = dropdownItems.getBoundingClientRect();
                    const isBelowView = dropdownItemsRect.bottom > sidebarRect.bottom;

                    if (isBelowView) {
                         const dropdownPosition = dropdownRect.top - sidebarRect.top + sidebarElement.scrollTop;

                         sidebarElement.scrollTo({ top: dropdownPosition, behavior: 'smooth' });
                    }
               }
          }
     }, [showDropdown]);

     const handleToggle = (event) => {
          if (typeof toggleCustom === 'function') {
               toggleCustom();
          } else {
               setShowDropdown(!showDropdown);
          }

          if (!showDropdown) {
               const dropdownEl = event.target.parentElement;

               if (dropdownEl) {
                    dropdownRef.current = dropdownEl;
               }
          }
     };

     return (
          <li className={`sidebar-nav-dropdown custom ${showDropdown ? 'c-show' : ''} ${className}`}>
               <CLink {...(path && { as: NavLink })} className="sidebar-nav-dropdown-toggle" onClick={handleToggle} to={path}>
                    {icon && <CIcon icon={icon} className="mr-2" />}
                    {name}
               </CLink>
               <ul className="sidebar-nav-dropdown-items">
                    {showButton && (
                         <li
                              className="sidebar-nav-item custom-btn-add"
                              onClick={() =>
                                   dispatch(
                                        setFlexibleModal({
                                             show: true,
                                             component: componentName,
                                        }),
                                   )
                              }
                         >
                              {/* eslint-disable jsx-a11y/anchor-is-valid */}
                              <a className="sidebar-nav-link">
                                   <CIcon icon="icon-plus" className="sidebar-nav-icon" />
                                   {nameButton}
                              </a>
                         </li>
                    )}

                    {children}
               </ul>
          </li>
     );
};

const SubscriberNewSidebar = () => {
     const dispatch = useDispatch();
     const history = useHistory();
     const defaultNavKey = {
          GROUP: 'reportingPlatform',
          MAIN_NAV: 'oneView',
     };
     const flexibleModal = useSelector((state) => state.theme.flexibleModal);
     const activeAccount = useSelector((state) => state.subscriber.activeAccount);
     const activeListeners = useSelector((state) => state.subscriber.activeListeners);
     const activeDestinations = useSelector((state) => state.subscriber.activeDestinations);
     const accountGoals = useSelector((state) => state.subscriber.accountGoals);
     const nativeECommerce = useSelector((state) => state.subscriber.nativeECommerce);
     const elementJustActivated = useSelector((state) => state.subscriber.elementJustActivated);
     const { showPopup: showPreviewBrowserPopup } = useSelector((state) => state.subscriber.previewBrowserRule);
     const customReports = useSelector((state) => state.subscriber.customReport.reports);
     const externalData = useSelector((state) => (state.subscriber.accountExternalData ? state.subscriber.accountExternalData.sources : null));

     const currentAccountId = useRef(activeAccount.id);
     const sidebarTopRef = useRef(null);
     const mainNavRefs = useRef({});
     const subNavRefs = useRef({});
     const currentItemActive = useRef({});

     const [isMinimizeSidebar, setIsMinimizeSidebar] = useState(false);
     const [isHoverSidebar, setIsHoverSidebar] = useState(false);
     const [activeGroup, setActiveGroup] = useState('');
     const [activeMainNavItem, setActiveMainNavItem] = useState('');
     const [activeDropdown, setActiveDropdown] = useState([]);
     const [hoverMainNavItem, setHoverMainNavItem] = useState('');
     const [initListNavigation, setInitListNavigation] = useState([]);
     const [subNavOutline, setSubNavOutline] = useState({});
     const [itemsHover, setItemsHover] = useState({});

     let reportId = useParams();
     const locationPathName = history.location.pathname;
     const currentActiveGroup = useMemo(() => defaultSubscriberNavGroup.find((group) => group.key === activeGroup), [activeGroup]);

     const handleSetFlexibleModal = (component, category) => {
          dispatch(
               setFlexibleModal({
                    show: true,
                    component,
                    category,
               }),
          );
     };

     const handleToggleSidebar = ({ toggleShowNavChild = false, forceShowNavChild = null }) => {
          const sidebar = document.querySelector('.new-sidebar');
          const wrapper = document.querySelector('.c-wrapper.v2');

          if (sidebar) {
               const sidebarClassList = sidebar.classList;
               const checkSidebarShowNavChild = sidebarClassList.contains('show-nav-child');
               const currentSidebarShowNavChild =
                    forceShowNavChild !== null ? forceShowNavChild : toggleShowNavChild ? !checkSidebarShowNavChild : checkSidebarShowNavChild;

               if (toggleShowNavChild) {
                    if (currentSidebarShowNavChild) {
                         setIsMinimizeSidebar(false);
                    } else {
                         setIsMinimizeSidebar(true);
                         setIsHoverSidebar(false);
                    }

                    if (wrapper) {
                         const wrapperClasList = wrapper.classList;
                         if (currentSidebarShowNavChild) {
                              wrapperClasList.add('show-nav-child');
                         } else {
                              wrapperClasList.remove('show-nav-child');
                         }
                    }
               }
          }
     };

     const handleResize = () => {
          if (window.innerWidth < 1430) {
               handleToggleSidebar({
                    toggleShowNavChild: true,
                    forceShowNavChild: false,
               });
          } else {
               handleToggleSidebar({
                    toggleShowNavChild: true,
                    forceShowNavChild: true,
               });
          }
     };

     useEffect(() => {
          window.addEventListener('resize', handleResize);
          return () => {
               window.removeEventListener('resize', handleResize);
          };
     }, []); // eslint-disable-line

     const handleActiveDashboard = () => {
          if (currentItemActive.current) {
               currentItemActive.current.group = defaultNavKey.GROUP;
               currentItemActive.current.mainNav = defaultNavKey.MAIN_NAV;
               currentItemActive.current.dropdowns = [];
          }

          setActiveGroup(defaultNavKey.GROUP);
          setActiveMainNavItem(defaultNavKey.MAIN_NAV);

          handleToggleSidebar({
               toggleShowNavChild: true,
               forceShowNavChild: true,
          });
     };

     useEffect(() => {
          if (currentAccountId && currentAccountId.current && activeAccount.id && currentAccountId.current !== activeAccount.id) {
               handleActiveDashboard();
          }
          currentAccountId.current = activeAccount.id;
     }, [activeAccount]); // eslint-disable-line

     const handleShowNavChild = (navItem) => {
          setActiveMainNavItem(navItem);

          if (navItem) {
               handleToggleSidebar({ toggleShowNavChild: true, forceShowNavChild: true });
          }
     };

     const getSubscriberGoals = useCallback(() => {
          const activeGoalsNav = getActiveGoals({
               accountGoals,
               activeListeners,
               nativeECommerce,
               elementJustActivated,
               handleSetFlexibleModal,
               reportId,
          });
          return [...activeGoalsNav];
     }, [accountGoals, elementJustActivated, activeListeners, nativeECommerce]); // eslint-disable-line

     const subscriberGoals = useMemo(() => getSubscriberGoals(activeDestinations), [activeDestinations, getSubscriberGoals]);

     const getSubscriberDataNav = useCallback(() => {
          const listenerNav = getSimpleListenerNav({
               elementJustActivated,
               activeListeners,
               reportId,
               externalData,
          });

          return [...listenerNav]; // eslint-disable-next-line
     }, [activeListeners, elementJustActivated, activeMainNavItem, externalData]); // eslint-disable-line

     const subscriberDataNav = useMemo(() => getSubscriberDataNav(activeListeners), [activeListeners, getSubscriberDataNav]);

     const getSubscriberDestinationNav = useCallback(() => {
          const activeDestinationsNav = getActiveDestinationNav({
               activeDestinations,
               className: 'has-button-add',
               elementJustActivated,
               handleSetFlexibleModal,
               reportId,
          });

          return [...activeDestinationsNav];
     }, [activeDestinations, elementJustActivated]); // eslint-disable-line

     const subscriberDestinationNav = useMemo(
          () => getSubscriberDestinationNav(activeDestinations),
          [activeDestinations, getSubscriberDestinationNav],
     );

     const customReportSubNavItem = useMemo(() => {
          if (!customReports) return;

          const defaultNewNavigation = { ...defaultSubscriberNewNavigation };
          const customReportItem = defaultNewNavigation.reportingPlatform?.find((item) => item.name === 'Custom');
          customReportItem._children = customReportItem._children.map((child) => {
               if (child.name === 'Tables') {
                    if (customReports.length > 0) {
                         const childrenArray = customReports
                              .filter((report) => report.status)
                              .map((report) => {
                                   return {
                                        _tag: 'CSidebarNavItem',
                                        name: report.name,
                                        to: NEW_NAVIGATION_PATH.CUSTOM_REPORT_DETAIL.replace(':id', report.id),
                                        exact: false,
                                   };
                              });
                         return {
                              ...child,
                              _tag: 'CSidebarNavDropdownCustom',
                              to: null,
                              path: NEW_NAVIGATION_PATH.CUSTOM_REPORTS,
                              type: 'dropdown',
                              _children: [...childrenArray],
                         };
                    } else {
                         return {
                              name: 'Tables',
                              _tag: 'CSidebarNavItem',
                              to: NEW_NAVIGATION_PATH.CUSTOM_REPORTS,
                         };
                    }
               }

               return child;
          });

          return customReportItem._children;
     }, [customReports]); // eslint-disable-line

     const objectivesSubNavItem = useMemo(() => {
          const navItemDefault = {
               _tag: 'CSidebarNavItem',
               name: 'MANAGE OBJECTIVES',
               icon: 'icon-plus',
               className: 'custom-btn-add',
               onClick: () => handleSetFlexibleModal(COMPONENT_NAME.GOAL_MANAGE_TYPES),
          };

          const objectivesChildren =
               subscriberGoals && subscriberGoals.length > 0
                    ? subscriberGoals.map((item) => {
                           if (item.className === 'custom-btn-add') {
                                return { ...navItemDefault };
                           }

                           return { ...item };
                      })
                    : [navItemDefault];

          return objectivesChildren;
     }, [subscriberGoals]); // eslint-disable-line

     const listenersSubNavItem = useMemo(() => {
          const navItemDefault = {
               name: 'MANAGE SOURCES',
               icon: 'icon-plus',
               className: 'custom-btn-add',
               onClick: () => handleSetFlexibleModal(COMPONENT_NAME.LISTENER_LIBRARY),
          };

          const listenersDataNav = subscriberDataNav?.find((child) => child.name === 'Listeners');
          const externalDataNav = subscriberDataNav?.find((child) => child.name === 'External Data');

          const listenersWebChildren = listenersDataNav
               ? [
                      navItemDefault,
                      ...listenersDataNav.data.map((group) => {
                           const category = group.find((item) => item.category)?.category;
                           let children = group.filter((item) => item._tag === 'CSidebarNavItem').map((item) => ({ ...item }));

                           if (children.length === 0) {
                                children = [
                                     {
                                          name: 'No Enabled Listeners',
                                          className: 'no-data',
                                     },
                                ];
                           }

                           return {
                                name: category,
                                _children: children,
                           };
                      }),
                 ]
               : [navItemDefault];

          const navItems = [
               {
                    name: 'Web',
                    _children: listenersWebChildren,
               },
               { ...externalDataNav, name: 'External', toggleCustom: null },
          ];

          return navItems;
     }, [subscriberDataNav]); // eslint-disable-line

     const destinationsSubNavItem = useMemo(() => {
          const navItemDefault = {
               _tag: 'CSidebarNavItem',
               name: 'MANAGE DESTINATIONS',
               icon: 'icon-plus',
               className: 'custom-btn-add',
               onClick: () => handleSetFlexibleModal(COMPONENT_NAME.DESTINATION_LIBRARY),
          };

          const destinationsChildren =
               subscriberDestinationNav && subscriberDestinationNav.length > 0
                    ? subscriberDestinationNav.map((item) => {
                           if (item.className === 'custom-btn-add') {
                                return { ...navItemDefault };
                           }

                           return { ...item };
                      })
                    : [navItemDefault];

          return destinationsChildren;
     }, [subscriberDestinationNav]); // eslint-disable-line

     const getNavChildItem = useCallback(
          (items, key = '') => {
               if (!Array.isArray(items) || items.length === 0) return [];

               const { secondId } = activeAccount;

               const childItem = items
                    .map((item) => {
                         const uniqueId = key ? `${item.name}_${key}` : `${item.name}`;
                         const hasChildren = Array.isArray(item._children);

                         if (item.name === 'Save Reports' && !activeAccount.savedReport) {
                              return null;
                         }

                         const newItems = {
                              ...item,
                              ...(item.path && { path: item.path.replace(':secondId', secondId) }),
                              ...(item.route && { route: item.route.replace(':secondId', secondId) }),
                              ...(item.to && { to: item.to.replace(':secondId', secondId) }),
                              _tag: hasChildren ? 'CSidebarNavDropdownCustom' : 'CSidebarNavItem',
                         };

                         if (hasChildren) {
                              const handleToggle = () => {
                                   setActiveDropdown((prev) => {
                                        if (prev.includes(uniqueId)) {
                                             return prev.filter((name) => name !== uniqueId);
                                        } else {
                                             return [...prev, uniqueId];
                                        }
                                   });
                              };

                              newItems.uniqueId = uniqueId;
                              newItems.toggleCustom = handleToggle;
                              newItems.show = activeDropdown ? activeDropdown.includes(uniqueId) : false;
                              newItems._children = getNavChildItem(item._children, key);
                         }

                         return newItems;
                    })
                    .filter((item) => item !== null);

               return childItem;
          },
          [activeAccount, activeDropdown],
     );

     const listNewNavigation = useMemo(() => {
          const listNav = [
               {
                    name: 'reportingPlatform',
                    data: defaultSubscriberNewNavigation.reportingPlatform,
               },
               {
                    name: 'dataPlatform',
                    data: defaultSubscriberNewNavigation.dataPlatform,
               },
               {
                    name: 'privacyPlatform',
                    data: defaultSubscriberNewNavigation.privacyPlatform,
               },
          ];

          const menuData = {
               objectives: objectivesSubNavItem,
               listeners: listenersSubNavItem,
               destinations: destinationsSubNavItem,
               customReport: customReportSubNavItem,
          };

          const newListNav = listNav.map(({ name, data }) => ({
               name,
               data: data.map((menuItem) => {
                    const newChildren = menuData[menuItem.key] || menuItem._children || [];

                    return {
                         group: name,
                         ...menuItem,
                         ...(newChildren.length && { _children: getNavChildItem(newChildren, menuItem.key) }),
                    };
               }),
          }));

          return newListNav;
     }, [objectivesSubNavItem, listenersSubNavItem, destinationsSubNavItem, customReportSubNavItem, activeDropdown]); // eslint-disable-line

     const subscriberMainNav = useMemo(() => {
          return listNewNavigation.find((item) => item.name === activeGroup)?.data || [];
     }, [listNewNavigation, activeGroup]); // eslint-disable-line

     useEffect(() => {
          if (subscriberGoals.length === 0 || subscriberDestinationNav.length === 0 || subscriberDataNav.length === 0) {
               return;
          }

          if (listNewNavigation && listNewNavigation.length > 0 && (!initListNavigation || initListNavigation.length === 0)) {
               setInitListNavigation(listNewNavigation);
          }
     }, [subscriberGoals, subscriberDestinationNav, subscriberDataNav, initListNavigation, listNewNavigation]);

     const handleSelectGroup = (key) => {
          setActiveGroup(key);

          if (currentItemActive.current && currentItemActive.current.group === key) {
               setActiveMainNavItem(currentItemActive.current.mainNav);
               setActiveDropdown(currentItemActive.current.dropdowns);
          } else {
               const activeMainNav = listNewNavigation.find((item) => item.name === key)?.data[0];
               if (activeMainNav) {
                    setActiveMainNavItem(activeMainNav.key);
               }
          }
     };

     const findItemByUrl = (item, url, dropdowns = []) => {
          const uniqueId = item.uniqueId || '';

          if (item.path && item.path === url) {
               return { ...item };
          }

          if (item.to && item.to === url) {
               return { ...item, dropdowns };
          }

          if (item._children) {
               for (let child of item._children) {
                    const id = uniqueId ? [...dropdowns, uniqueId] : dropdowns;
                    const result = findItemByUrl(child, url, id);

                    if (result) {
                         return result;
                    }
               }
          }
          return null;
     };

     const getActiveNavigation = (data, url) => {
          for (let group of data) {
               for (let item of group.data) {
                    const result = findItemByUrl(item, url);

                    if (result) {
                         return {
                              mainNav: item.key,
                              group: group.name,
                              name: result.name,
                              url: result.to,
                              dropdowns: result.dropdowns,
                         };
                    }
               }
          }
          return null;
     };

     const processNavigation = (listNavigation, pathName) => {
          const newUrl = pathName.replace(/\/$/, '');
          const result = getActiveNavigation(listNavigation, newUrl);

          if (result) {
               const { mainNav, group, dropdowns } = result;

               if (group === 'reportingPlatform') {
                    dispatch(setNewLayout({ showHeader: false }));
               } else {
                    dispatch(setNewLayout({ showHeader: true }));
               }

               if (currentItemActive.current) {
                    currentItemActive.current.group = group;
                    currentItemActive.current.mainNav = mainNav;
                    currentItemActive.current.dropdowns = dropdowns;
               }

               if (group !== activeGroup) {
                    setActiveGroup(group);
               }

               if (mainNav !== activeMainNavItem) {
                    handleShowNavChild(mainNav);
               }

               if (dropdowns) {
                    setActiveDropdown(dropdowns);
               }
          } else {
               handleActiveDashboard();
          }
     };

     useEffect(() => {
          if (initListNavigation && initListNavigation.length > 0) {
               const newUrl = locationPathName.replace(/\/$/, '');

               if (newUrl === `/${reportId.secondId}`) {
                    handleActiveDashboard();
               } else {
                    processNavigation(initListNavigation, locationPathName);
               }

               if (newUrl === SUBSCRIBER_PATH.INSIGHTS_REVEAL.replace(':secondId', reportId.secondId)) {
                    setActiveGroup('privacyPlatform');
                    setActiveMainNavItem('reveal');
               }
          }
     }, [initListNavigation]); // eslint-disable-line

     useEffect(() => {
          const unlisten = history.listen(() => {
               const pathName = history.location.pathname;

               if (pathName) {
                    dispatch(setNewLayout({ showHeader: true }));

                    if (showPreviewBrowserPopup) {
                         dispatch(setPreviewBrowserRule({ showPopup: false }));
                    }

                    if (pathName === `/${reportId.secondId}` && !flexibleModal.show) {
                         handleActiveDashboard();
                    } else if (listNewNavigation && listNewNavigation.length > 0) {
                         processNavigation(listNewNavigation, pathName);
                    }

                    if (pathName === SUBSCRIBER_PATH.INSIGHTS_REVEAL.replace(':secondId', reportId.secondId)) {
                         setActiveGroup('privacyPlatform');
                         setActiveMainNavItem('reveal');
                    }
               }
          });

          return () => unlisten();
     }, [listNewNavigation, flexibleModal]); // eslint-disable-line

     const setMainNavItemRef = (type, key, element) => {
          if (element) {
               if (type === 'main') {
                    mainNavRefs.current[key] = element;
               } else {
                    subNavRefs.current[key] = element;
               }
          }
     };

     useEffect(() => {
          const sidebarTop = sidebarTopRef.current;
          const mainNavItem = mainNavRefs.current[hoverMainNavItem];
          const subNavItem = subNavRefs.current[hoverMainNavItem];

          if (!subNavItem || !mainNavItem || !sidebarTop || hoverMainNavItem === activeMainNavItem) return;

          const observer = new ResizeObserver(() => {
               const sidebarTopHeight = sidebarTop.getBoundingClientRect().height;
               const mainNavItemTop = mainNavItem.getBoundingClientRect().top;
               const mainNavItemHeight = mainNavItemTop + 58;
               const subNavItemHeight = subNavItem.getBoundingClientRect().height;
               const distanceMainNav = mainNavItemHeight - sidebarTopHeight;

               if (distanceMainNav >= subNavItemHeight) {
                    setSubNavOutline({
                         [`${hoverMainNavItem}_radius`]: true,
                         [`${hoverMainNavItem}_height`]: `${distanceMainNav}px`,
                    });
               } else {
                    setSubNavOutline({
                         [`${hoverMainNavItem}_height`]: `${subNavItemHeight}px`,
                    });
               }
          });

          observer.observe(subNavItem);

          return () => observer.disconnect();
     }, [hoverMainNavItem, activeMainNavItem]);

     const handleSidebarHover = () => {
          const body = document.body;
          body.style.overflow = 'hidden';

          setIsHoverSidebar(true);
     };

     const handleSidebarMouseLeave = () => {
          const body = document.body;
          body.style.overflow = '';

          setIsHoverSidebar(false);
          setHoverMainNavItem(null);
     };

     const handleSubItemHover = (event) => {
          const element = event.target;

          if (element.classList.contains('sidebar-nav-link')) {
               const textEl = element.querySelector('span');

               if (textEl) {
                    const scrollWidth = textEl.scrollWidth;
                    const clientWidth = textEl.clientWidth;
                    const isEllipsized = scrollWidth > clientWidth;

                    if (isEllipsized) {
                         setItemsHover((prev) => ({ ...prev, name: textEl.textContent, isExpanded: true }));
                    } else {
                         setItemsHover((prev) => {
                              if (prev.name !== textEl.textContent) {
                                   return { ...prev, name: textEl.textContent, isExpanded: false };
                              }

                              return { ...prev };
                         });
                    }
               }
          }
     };

     return (
          <CSidebar
               onMouseEnter={handleSidebarHover}
               onMouseLeave={handleSidebarMouseLeave}
               onVisibleChange={(val) => dispatch(setSidebarShow(val))}
               className={`sidebar-v2 new-sidebar${!isMinimizeSidebar ? ' show-nav-child' : ' minimize-sidebar'}${isHoverSidebar ? ' is-hover' : ''}`}
               position="fixed"
               placement="start"
               size="lg"
          >
               <div className="sidebar-top" ref={sidebarTopRef}>
                    <div className="sidebar-top--logo">
                         <CSidebarBrand className="d-md-down-none" to={`/${reportId.secondId}`} as={Link}>
                              {isMinimizeSidebar ? (
                                   <CIcon className="sidebar-brand-full" icon="favicon" height={24} />
                              ) : (
                                   <CIcon className="sidebar-brand-full" icon="logo-light" height={24} />
                              )}
                         </CSidebarBrand>

                         <CToggler inHeader onClick={() => handleToggleSidebar({ toggleShowNavChild: true })}>
                              <CIcon icon="iconCollapsed" />
                         </CToggler>
                    </div>
                    <Dropdown className="sidebar-top--dropdown">
                         <DropdownToggle>
                              <CIcon icon={currentActiveGroup?.icon} />
                              {!isMinimizeSidebar && <span>{currentActiveGroup?.name}</span>}
                         </DropdownToggle>
                         <DropdownMenu>
                              {defaultSubscriberNavGroup.map((group) => {
                                   return (
                                        <React.Fragment key={group.key}>
                                             <DropdownItem
                                                  active={activeGroup === group.key ? true : false}
                                                  onClick={() => handleSelectGroup(group.key)}
                                             >
                                                  <CIcon icon={group.icon} />
                                                  <span>{group.name}</span>

                                                  {activeGroup === group.key && <CBadge className="label-current">CURRENT</CBadge>}
                                             </DropdownItem>
                                        </React.Fragment>
                                   );
                              })}
                         </DropdownMenu>
                    </Dropdown>
               </div>

               {activeAccount.id && (
                    <>
                         <div className="sidebar-content-wrap">
                              <div className="sidebar-nav-list sidebar-lv1">
                                   <CSidebarNav>
                                        {subscriberMainNav.map((menu) => {
                                             return (
                                                  <React.Fragment key={menu.key}>
                                                       <CSidebarNavItem
                                                            innerRef={(el) => setMainNavItemRef('main', menu.key, el)}
                                                            className={`has-child${activeMainNavItem === menu.key ? ' active' : ''}${
                                                                 hoverMainNavItem === menu.key ? ' is-hover' : ''
                                                            }`}
                                                            name={menu.name}
                                                            icon={menu.icon}
                                                            onClick={() => handleShowNavChild(menu.key)}
                                                            onMouseEnter={() => setHoverMainNavItem(menu.key)}
                                                       />
                                                  </React.Fragment>
                                             );
                                        })}
                                   </CSidebarNav>
                              </div>
                              <div className={`sidebar-nav-list sidebar-lv2${itemsHover && itemsHover.isExpanded ? ' is-expanded' : ''}`}>
                                   <div className={`sidebar-items-wrap scroll-y`}>
                                        {subscriberMainNav.map((menu) => {
                                             const items = menu._children || [];

                                             return (
                                                  <div
                                                       key={menu.key}
                                                       ref={(el) => setMainNavItemRef('sub', menu.key, el)}
                                                       className={`navigation-list${activeMainNavItem === menu.key ? ' show' : ''}${
                                                            !isMinimizeSidebar && activeMainNavItem !== menu.key && hoverMainNavItem === menu.key
                                                                 ? ' show is-hover'
                                                                 : ''
                                                       }`}
                                                       onMouseOver={handleSubItemHover}
                                                       onMouseEnter={() => setHoverMainNavItem(menu.key)}
                                                       onMouseLeave={() => {
                                                            setItemsHover({});
                                                            setHoverMainNavItem(null);
                                                       }}
                                                       style={
                                                            hoverMainNavItem === menu.key
                                                                 ? {
                                                                        ...(items.length === 0 && { '--outline': 0 }),
                                                                        ...(subNavOutline[`${menu.key}_radius`] && {
                                                                             '--radius': 0,
                                                                        }),
                                                                        ...(subNavOutline[`${menu.key}_height`] && {
                                                                             '--height': `${subNavOutline[`${menu.key}_height`]}`,
                                                                        }),
                                                                   }
                                                                 : {}
                                                       }
                                                  >
                                                       <CCreateElement
                                                            items={items}
                                                            components={{
                                                                 CSidebarNavDropdown,
                                                                 CSidebarNavItem,
                                                                 CSidebarNavDropdownCustom,
                                                            }}
                                                       />
                                                  </div>
                                             );
                                        })}
                                   </div>
                              </div>
                         </div>

                         <div className="sidebar-footer">
                              <SubscriberNewSidebarFooter isMinimizeSidebar={isMinimizeSidebar} />
                         </div>
                    </>
               )}
          </CSidebar>
     );
};

export default React.memo(SubscriberNewSidebar);
